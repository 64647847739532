@tailwind base;
@tailwind utilities;
@tailwind components;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

@font-face {
  font-family: 'Inter';
  src: url('./styles/fonts/Inter-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Light';
  src: url('./styles/fonts/Inter-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "InterMedium";
  src: url("./styles/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: 'Inter Bold';
  src: url('./styles/fonts/Inter-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Inter Black";
  src: url("./styles/fonts/Inter-Black.ttf");
}

@font-face {
  font-family: 'PP Right Gothic Medium';
  src: url('./styles/fonts/PPRightGothic-Medium.otf') format('truetype');
  font-display: swap;
}

* {
  font-family: 'Inter Regular', sans-serif;
}

b, strong {
  font-family: 'Inter Bold', sans-serif;
}

p {
  font-size: 1rem;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: 'Inter Regular', sans-serif !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

h1 {
  font-size: 2.375rem;
  line-height: 2.75rem;
}

h2 {
  font-size: 2rem;
  letter-spacing: -0.25px;
  line-height: 2.375rem;
}

h4 {
  letter-spacing: 0.016rem;
  line-height: 1.875rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.inter-light {
  font-family: 'Inter Light', sans-serif !important;
}

.inter-regular {
  font-family: 'Inter Regular', sans-serif !important;
}

.inter-medium {
  font-family: 'Inter Medium', sans-serif !important;
}

.inter-bold {
  font-family: 'Inter Bold', sans-serif !important;
}

.inter-black {
  font-family: 'Inter Black', sans-serif !important;
}

.gothic-medium {
  font-family: 'PP Right Gothic Medium', sans-serif !important;
}

.container {
  max-width: 1280px !important;
}

.gray_base-font {
  color: #97939f;
}

.h-px {
  height: 1px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 2rem !important;
}

@media (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 2rem !important;
}

@media (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.card {
  background-color: #fff;
  padding: 34px 20px 8px;
  box-shadow: none !important;
  border-radius: 10px;
  text-align: left;
  z-index: 1;
  margin: auto auto 20px;
  overflow: hidden;
}

.card:hover {
  transition: box-shadow 0.5s ease-in-out !important;
  box-shadow: 0 0 20px 0 rgba(65, 61, 69, .1) !important;
}

.card, .card-input, .initial-dialog {
  max-width: 672px;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

@media (min-width: 1200px) {
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

@media (min-width: 1200px) {
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
}

.duration-200 {
  transition-duration: 0.2s;
}

.logo {
  max-width: 215px;
}

@media (min-width: 1024px) {
  .lg-fixed {
    position: fixed;
  }
}

.min-h-screen {
  min-height: 100vh;
}

.form-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.016rem;
}

.form-control {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  border-radius: 0.125rem !important;
}

.text-base {
  font-size: 1rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-title-xxl {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

.text-darkblue {
  color: #465bc4 !important;
}

.text-deepblue {
  color: #002733 !important;
}

.bg-cream {
  background-color: #f7f5f3;
}

.bg-offwhite {
  background-color: #f9f8f6;
}

.bg-deepblue {
  color: #002733;
}

.bg-grey {
  background-color: #d8d6db;
}

@media (min-width: 768px) {
  .gray-left-border {
    border-right: 1px solid #e5e4e7;
    height: 80%;
  }
}

.btn-deepblue {
  background-color: #002733 !important;
}

.btn-deepblue:hover {
  background-color: rgba(0, 39, 51, .761) !important;
}

.btn-next {
  line-height: 1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.logo-fixed {
  top: 1rem;
  left: 1rem;
}

@media (min-width: 992px) {
  .logo-fixed {
    top: 2rem;
    left: 2rem;
  }
}

.login-bg {
  top: 14rem;
  margin-top: 12rem;
  width: auto;
}

.login-bg img {
  max-width: 100%;
  height: auto;
}

.login-bg__hidden {
  width: 18rem;
}

.login-bg__left {
  top: 6rem;
  right: 16rem;
  margin-top: 3rem;
  margin-left: 7rem;
  width: 12rem;
}

.login-bg__right {
  width: 20rem;
  right: 1rem;
}

@media (min-width: 576px) {
  .login-card {
    max-width: 36rem;
  }
}

.main-navigation {
  min-height: 80px;
  color: #3f3c44;
  border-bottom: 2px solid #e3dbd4;
  background-color: #f9f8f6 !important;
}

@media (min-width: 991px) {
  .main-navigation {
    margin: 3px 29px 0 24px !important;
    background-color: #fff !important;
  }
}

.main-container {
  min-height: calc(100vh - 80px);
  margin: 0 !important;
  overflow: hidden;
  width: auto !important;
  transition: margin 0.2s linear;
  padding: 65px 0 10rem 0 !important;
}

@media (min-width: 768px) {
  .main-container {
    margin: 0 29px 0 24px !important;
  }
}

.main-container__wrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.nav-link {
  top: 4px !important;
  text-transform: uppercase;
}

@media (min-width: 991px) {
  .nav-link--account {
    position: absolute;
    right: 5px;
  }
}

@media (min-width: 991px) {
  .navbar-nav .nav-link {
    border-bottom: 2px solid #e3dbd4;
  }
}

@media (min-width: 991px) {
  .navbar-nav .nav-link.active:not(.nav-link--account) {
    border-bottom: 2px solid #708dc6;
  }
}

.navbar-toggler {
  border: 0 solid transparent !important;
  border-radius: 0 !important;
  border-left: 1px solid #e6e4e7 !important;
}

.layout-dashboard {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  transition: padding 0.2s linear;
}

.stat {
  padding: 10px;
}

.mat-row {
  padding: 8px 0;
}

.mat-table {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, .12);
}

.mat-header-row {
  min-height: 56px;
}

.mat-row, .mat-header-row {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  align-items: center;
  box-sizing: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  word-wrap: normal;
  display: table-cell;
  padding: 0 10px;
  line-break: unset;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.mat-header-cell button {
  background-color: transparent;
  border-width: 0;
}

.mat-sort-header, .mat-cell {
  padding: 0 10px;
  line-break: unset;
  overflow: hidden;
  vertical-align: middle;
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 1;
}

.mat-sort-header button, .mat-cell button {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.mat-form-field {
  line-height: 1.125;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  position: relative;
}

.mat-checkbox-input {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.mat-checkbox-input.small {
  width: 16px;
  height: 16px;
}

.mat-checkbox-input.checked {
  position: relative;
}

.mat-checkbox-input.checked::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' focusable='false' viewBox='0 0 24 24' xml:space='preserve' class='mat-checkbox-checkmark'%3E%3Cpath fill='none' stroke='white' d='M4.1,12.7 9,17.6 20.3,6.3' class='mat-checkbox-checkmark-path'%3E%3C/path%3E%3C/svg%3E");
  background-size: 24px;
  background-repeat: no-repeat;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 6px;
  top: 3px;
}

.mat-checkbox-input.small.checked::before {
  background-size: 13px;
  width: 13px;
  height: 13px;
  left: 0;
  top: 0;
}

.mat-checkbox-input.small.checked:disabled {
  background-color: #C4D8FF;
}

.table-search-input:focus-visible {
  outline: none;
}

.mat-checkbox-layout .mat-checkbox-inner-container {
  width: 32px;
  height: 32px;
}

textarea[name="clientEmailCopy"] {
  height: 280px;
}

.invite-full-screen-dialog .mat-checkbox-layout .image {
  max-width: 470px;
}

.radio-select-group {
  transition: box-shadow 0.5s ease-in-out !important;
  max-width: 672px;
  margin: auto;
  background-color: #fff;
  font-family: 'Inter Regular', sans-serif !important;
  color: #413d45;
  width: 100%;
  position: relative;
  border: 1px solid #ebe8e4;
  border-radius: 10px !important;
  z-index: 1;
  overflow: hidden;
}

.radio-select-input {
  appearance: none;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
  border: 2px solid #d6a600;
  background-color: #fff;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.radio-select-input.checked {
  background-color: #d6a600;
  position: relative;
  border: 2px solid #fff;
}

.radio-select-input.checked::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' focusable='false' viewBox='0 0 24 24' xml:space='preserve' class='mat-checkbox-checkmark'%3E%3Cpath fill='none' stroke='white' d='M4.1,12.7 9,17.6 20.3,6.3' class='mat-checkbox-checkmark-path'%3E%3C/path%3E%3C/svg%3E");
  background-size: 16px;
  background-repeat: no-repeat;
  position: absolute;
  width: 16px;
  height: 16px;
  right: -1px;
  top: 0;
}

.radio-select-container {
  padding: 0 20px;
}

.radio-select-container.selected {
  background-color: #d6a600 !important;
  border-color: #d6a600 !important;
}

.radio-select-container label {
  padding: 15px 0 15px 0;
  cursor: pointer;
}

#mat-select-1 {
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  font-size: 24px;
  font-family: 'PP Right Gothic Medium', sans-serif !important;
}

#mat-select-1:focus {
  box-shadow: none;
}

.my-account .password-row {
  justify-content: space-between;
  padding: 2rem 2.5rem;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 54px;
  height: 30px;
  background: #fff;

  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: 0.2s;
  background: #736F62;
}

.switch-label:active .switch-button {
  width: 30px;
}

.menu {
  margin-bottom: -1px;
}

.main-nav,
.login-nav {
  top: -5px;
}

@media (min-width: 768px) {
  .main-nav,
  .login-nav {
    top: -21px;
  }
}

.main-nav li {
  list-style: none outside none;
}

.main-nav li a {
  padding: 7px 8px;
}

@media (min-width: 768px) {
  .main-nav li a {
    padding: 16px 24px;
  }
}

.main-nav li.active a,
.login-nav li.active a {
  padding: 6px 8px 7px;
}

@media (min-width: 768px) {
  .main-nav li.active a,
  .login-nav li.active a {
    padding: 15px 24px 16px;
  }
}

.login-nav li.login a {
  padding: 15px 24px;
}

.main-nav li.active a:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 103.5%;
  background-color: #fff;
  left: -3px;
  bottom: -4px;
}

.main-nav li:first-child.active a:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 101.8%;
  background-color: #fff;
  left: -1px;
  bottom: -5px;
  border-left: 1px solid #CECABA;
  border-top-right-radius: 5px;
}

.login-nav li.active a:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 101.8%;
  background-color: #fff;
  right: -1px;
  bottom: -6px;
  border-right: 1px solid #CECABA;
  z-index: 1;
  border-top-left-radius: 5px;
}

nav:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  border-top: 1px solid #CECABA;
  border-left: 1px solid #CECABA;
  border-right: 1px solid #CECABA;
  height: 100%;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media (min-width: 768px) {
  nav:after {
    height: 20px;
    bottom: -1px;
  }
}

.active {
  border: 1px solid #CECABA;
  border-bottom: 0;
  position: relative;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  background-color: #fff;
}

.active:after,
.active:before {
  content: '';
  width: 40px;
  height: 30px;
  border: 1px solid #CECABA;
  position: absolute;
  bottom: -3px;
  border-top: 0;
}

.main-nav .active:first-child:after {
  content: none;
}

.login-nav .active:before {
  content: none;
}

.active:after {
  border-left: 0;
  -moz-border-radius: 0 0 5px 0;
  -webkit-border-radius: 0 0 5px 0;
  left: -40px;
}

.active:before {
  border-right: 0;
  -moz-border-radius: 0 0 0 5px;
  -webkit-border-radius: 0 0 0 5px;
  right: -40px;
}

.w-24 {
  width: 48px;
}

textarea {
  width: 100%;
  height: 150px;
}

.table-row:nth-child(even) {
  background-color: #E8E3D2;
}

.filter-list {
  z-index: 3;
}

.filter-list:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #cecaba transparent;
  border-width: 0 13px 7px;
  top: -7px;
  left: 82%;
  margin-left: -11px;
}

.filter-list:before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  border-style: solid;
  border-color: #fff transparent;
  border-width: 0 12px 6px;
  top: -6px;
  left: 82.5%;
  margin-left: -11px;
  z-index: 2;
}

.filter-sublist:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #cecaba;
  border-width: 13px 7px 13px 0;
  top: 14%;
  left: -6px;
  margin-top: -13px;
}

.filter-sublist:before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  border-style: solid;
  border-color: transparent #fff;
  border-width: 12px 6px 12px 0;
  top: 15%;
  left: -5px;
  margin-top: -13px;
  z-index: 2;
}

.filter-button:hover {
  background-color: #E8E3D2;
  color: #0C2632;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml, %3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7.20748 4.23214C7.49349 3.93252 7.96823 3.92148 8.26786 4.20748L13.7679 9.45748C13.9161 9.59901 14 9.79504 14 10C14 10.205 13.9161 10.401 13.7679 10.5425L8.26786 15.7925C7.96823 16.0785 7.49349 16.0675 7.20748 15.7679C6.92148 15.4682 6.93252 14.9935 7.23214 14.7075L12.1638 10L7.23214 5.29252C6.93252 5.00651 6.92148 4.53177 7.20748 4.23214Z'%0Atransform='translate(22 0) rotate(90)' fill='%230C2632'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 98% center;
}

[class$="control"]:hover {
  border-color:#CFCAB8!important;
}

body {
  direction: rtl;
}

#root {
  direction:ltr
}

#modal {
  direction: ltr;
}

.family-list {
  direction: rtl;
}

.family-list>div {
  direction: ltr;
}

.family-list::-webkit-scrollbar {
  width: 12px;
  margin-left: 10px;
}

.family-list::-webkit-scrollbar-track {
  border-radius: 20px;
}

.family-list::-webkit-scrollbar-thumb {
  background: rgba(12, 38, 50, 0.6);
  border-radius: 10px;
}
